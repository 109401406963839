import 'normalize.css'
// import 'typeface-domine'
// import 'typeface-montserrat'
import 'prismjs/themes/prism-okaidia.css'

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
  }
}
